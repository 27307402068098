<template>
  <div id="app">
    <div v-if="!authStore.sessionChecked" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    <template v-else>
      <Header @open-cart="isCartOpen = true" />
      <router-view />
      <Footer />
      <CartDrawer :is-open="isCartOpen" @close="isCartOpen = false" />
      <SessionTimeoutWarning />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CartDrawer from "@/components/CartDrawer.vue";
import SessionTimeoutWarning from "@/components/SessionTimeoutWarning.vue";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const isCartOpen = ref(false);
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #0c1cb6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
