import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useAuthStore } from "@/stores/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase";

export const initializeAuth = () => {
  const auth = getAuth();
  const authStore = useAuthStore();

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      try {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (user.emailVerified && !userData.emailVerified) {
            await updateDoc(doc(db, "users", user.uid), {
              emailVerified: true,
              updatedAt: new Date(),
            });
            userData.emailVerified = true;
          }

          authStore.user = {
            ...user,
            ...userData,
            emailVerified: user.emailVerified,
          };
          authStore.userRole = userData.role;
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        authStore.user = null;
        authStore.userRole = null;
      }
    } else {
      authStore.user = null;
      authStore.userRole = null;
    }
    authStore.sessionChecked = true;
  });
};
