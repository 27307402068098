import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "cart-header" }
const _hoisted_2 = {
  key: 0,
  class: "empty-cart"
}
const _hoisted_3 = { class: "cart-items" }
const _hoisted_4 = { class: "item-image" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "item-details" }
const _hoisted_7 = { class: "price" }
const _hoisted_8 = { class: "quantity-controls" }
const _hoisted_9 = ["onClick", "disabled"]
const _hoisted_10 = { class: "quantity" }
const _hoisted_11 = ["onClick", "disabled"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "cart-footer" }
const _hoisted_14 = { class: "total" }
const _hoisted_15 = { class: "total-amount" }

import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CartItem } from "@/stores/cart";

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'CartDrawer',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const store = useStore();
const router = useRouter();
// eslint-disable-next-line no-undef
const emit = __emit;

const cartItems = computed(() => store.state.cart.items);
const cartTotal = computed(() => store.getters["cart/cartTotal"]);

const goToStore = () => {
  router.push("/store");
  emit("close");
};

const formatPrice = (price: number) => {
  return Math.round(Number(price)).toString();
};

const updateItemQuantity = (productId: string, newQuantity: number) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const item = cartItems.value.find(
    (i: { productId: string }) => i.productId === productId
  );
  if (!item) return;

  if (newQuantity > 0 && newQuantity <= item.stock) {
    store.dispatch("cart/updateQuantity", {
      productId,
      quantity: Number(newQuantity),
    });
  }
};

const removeItem = (productId: string) => {
  store.dispatch("cart/removeFromCart", productId);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cart-drawer", { 'is-open': _ctx.isOpen }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Carrito de Compras", -1)),
      _createElementVNode("button", {
        class: "close-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, "×")
    ]),
    (cartItems.value.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("p", null, "Tu carrito está vacío", -1)),
          _createElementVNode("button", {
            class: "continue-shopping",
            onClick: goToStore
          }, " Continuar comprando ")
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cartItems.value, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.productId,
                class: "cart-item"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("img", {
                    src: item.imageUrl,
                    alt: item.name
                  }, null, 8, _hoisted_5)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h3", null, _toDisplayString(item.name), 1),
                  _createElementVNode("div", _hoisted_7, "$" + _toDisplayString(formatPrice(item.price)), 1),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("button", {
                      class: "quantity-btn",
                      onClick: ($event: any) => (
                  updateItemQuantity(item.productId, Number(item.quantity) - 1)
                ),
                      disabled: item.quantity <= 1
                    }, " - ", 8, _hoisted_9),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(item.quantity), 1),
                    _createElementVNode("button", {
                      class: "quantity-btn",
                      onClick: ($event: any) => (
                  updateItemQuantity(item.productId, Number(item.quantity) + 1)
                ),
                      disabled: item.quantity >= item.stock
                    }, " + ", 8, _hoisted_11)
                  ])
                ]),
                _createElementVNode("button", {
                  class: "remove-btn",
                  onClick: ($event: any) => (removeItem(item.productId))
                }, " × ", 8, _hoisted_12)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[3] || (_cache[3] = _createElementVNode("span", null, "Total:", -1)),
              _createElementVNode("span", _hoisted_15, "$" + _toDisplayString(formatPrice(cartTotal.value)), 1)
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("button", { class: "checkout-button" }, "Proceder al pago", -1))
          ])
        ], 64))
  ], 2))
}
}

})