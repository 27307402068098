import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 0,
  class: "loading-overlay"
}

import { ref } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CartDrawer from "@/components/CartDrawer.vue";
import SessionTimeoutWarning from "@/components/SessionTimeoutWarning.vue";
import { useAuthStore } from "@/stores/auth";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const authStore = useAuthStore();
const isCartOpen = ref(false);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_unref(authStore).sessionChecked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "loading-spinner" }, null, -1)
        ])))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(Header, {
            onOpenCart: _cache[0] || (_cache[0] = ($event: any) => (isCartOpen.value = true))
          }),
          _createVNode(_component_router_view),
          _createVNode(Footer),
          _createVNode(CartDrawer, {
            "is-open": isCartOpen.value,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (isCartOpen.value = false))
          }, null, 8, ["is-open"]),
          _createVNode(SessionTimeoutWarning)
        ], 64))
  ]))
}
}

})