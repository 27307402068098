<!-- eslint-disable no-undef -->
<!-- eslint-disable no-undef -->
<template>
  <div class="cart-drawer" :class="{ 'is-open': isOpen }">
    <div class="cart-header">
      <h2>Carrito de Compras</h2>
      <button class="close-button" @click="$emit('close')">&times;</button>
    </div>
    <div v-if="cartItems.length === 0" class="empty-cart">
      <p>Tu carrito está vacío</p>
      <button class="continue-shopping" @click="goToStore">
        Continuar comprando
      </button>
    </div>

    <template v-else>
      <div class="cart-items">
        <div v-for="item in cartItems" :key="item.productId" class="cart-item">
          <div class="item-image">
            <img :src="item.imageUrl" :alt="item.name" />
          </div>
          <div class="item-details">
            <h3>{{ item.name }}</h3>
            <div class="price">${{ formatPrice(item.price) }}</div>
            <div class="quantity-controls">
              <button
                class="quantity-btn"
                @click="
                  updateItemQuantity(item.productId, Number(item.quantity) - 1)
                "
                :disabled="item.quantity <= 1"
              >
                -
              </button>
              <span class="quantity">{{ item.quantity }}</span>
              <button
                class="quantity-btn"
                @click="
                  updateItemQuantity(item.productId, Number(item.quantity) + 1)
                "
                :disabled="item.quantity >= item.stock"
              >
                +
              </button>
            </div>
          </div>

          <button class="remove-btn" @click="removeItem(item.productId)">
            &times;
          </button>
        </div>
      </div>

      <div class="cart-footer">
        <div class="total">
          <span>Total:</span>
          <span class="total-amount">${{ formatPrice(cartTotal) }}</span>
        </div>
        <button class="checkout-button">Proceder al pago</button>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CartItem } from "@/stores/cart";

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = defineProps<{
  isOpen: boolean;
}>();

const store = useStore();
const router = useRouter();
// eslint-disable-next-line no-undef
const emit = defineEmits(["close"]);

const cartItems = computed(() => store.state.cart.items);
const cartTotal = computed(() => store.getters["cart/cartTotal"]);

const goToStore = () => {
  router.push("/store");
  emit("close");
};

const formatPrice = (price: number) => {
  return Math.round(Number(price)).toString();
};

const updateItemQuantity = (productId: string, newQuantity: number) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const item = cartItems.value.find(
    (i: { productId: string }) => i.productId === productId
  );
  if (!item) return;

  if (newQuantity > 0 && newQuantity <= item.stock) {
    store.dispatch("cart/updateQuantity", {
      productId,
      quantity: Number(newQuantity),
    });
  }
};

const removeItem = (productId: string) => {
  store.dispatch("cart/removeFromCart", productId);
};
</script>

<style scoped>
.cart-drawer {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100vh;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.cart-drawer.is-open {
  right: 0;
}

.cart-header {
  padding: 1rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.cart-items {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  position: relative;
}

.item-image {
  width: 80px;
  height: 80px;
  margin-right: 1rem;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.item-details {
  flex: 1;
}

.item-details h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
}

.price {
  color: var(--primary-red);
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantity-btn {
  background: #f0f0f0;
  border: none;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quantity {
  min-width: 24px;
  text-align: center;
}

.remove-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #666;
  cursor: pointer;
}

.cart-footer {
  padding: 1rem;
  border-top: 1px solid #eee;
}

.total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-weight: bold;
}

.checkout-button {
  width: 100%;
  padding: 0.8rem;
  background: var(--primary-red);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.checkout-button:hover {
  background: #a01423;
}

.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
  text-align: center;
}

.continue-shopping {
  margin-top: 1rem;
  padding: 0.8rem 1.5rem;
  background-color: var(--primary-red);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.continue-shopping:hover {
  background-color: #a01423;
}
</style>
