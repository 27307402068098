/* eslint-disable @typescript-eslint/no-unused-vars */
import { createRouter, createWebHistory } from "vue-router";
import { getAuth, User, onAuthStateChanged } from "firebase/auth";
import { useAuthStore } from "@/stores/auth";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/store",
      name: "Store",
      component: () => import("@/views/Store.vue"),
    },
    {
      path: "/contact",
      name: "Contact",
      component: () => import("@/views/Contact.vue"),
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/Login.vue"),
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("@/views/Register.vue"),
    },
    {
      path: "/email-verification",
      name: "EmailVerification",
      component: () => import("@/views/EmailVerification.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/client-dashboard",
      name: "ClientDashboard",
      component: () => import("@/views/CustomerDashboard.vue"),
      meta: {
        requiresAuth: true,
        requiresVerification: true,
        requiresClient: true,
      },
    },
    {
      path: "/staff-dashboard",
      name: "StaffDashboard",
      component: () => import("@/views/StaffDashboard.vue"),
      meta: {
        requiresAuth: true,
        requiresStaff: true,
      },
    },
    {
      path: "/client-orders/:id",
      name: "ClientOrders",
      component: () => import("@/views/ClientOrders.vue"),
      meta: { requiresAuth: true, requiresStaff: true },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const auth = getAuth();

  // Wait for initial auth check
  if (!authStore.sessionChecked) {
    await new Promise((resolve) => {
      const unsubscribe = onAuthStateChanged(auth, () => {
        unsubscribe();
        resolve(true);
      });
    });
  }

  // If trying to access login/register while already authenticated
  if ((to.path === "/login" || to.path === "/register") && authStore.user) {
    if (authStore.userRole === "staff") {
      next("/staff-dashboard");
    } else {
      next("/client-dashboard");
    }
    return;
  }

  // Protected routes check
  if (to.meta.requiresAuth && !authStore.user) {
    next("/login");
  } else if (
    to.meta.requiresVerification &&
    authStore.user &&
    !(authStore.user as User).emailVerified
  ) {
    next("/email-verification");
  } else if (to.path === "/staff-dashboard" && authStore.userRole !== "staff") {
    next("/unauthorized");
  } else if (
    to.path === "/client-dashboard" &&
    authStore.userRole === "staff"
  ) {
    next("/staff-dashboard");
  } else if (
    to.path === "/client-dashboard" &&
    authStore.userRole !== "client"
  ) {
    next("/unauthorized");
  } else {
    next();
  }
});

export default router;
