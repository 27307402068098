// main.ts
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import FontAwesomeIcon from "./plugins/fontawesome";
import "./assets/styles.scss";
import "./firebase";
import { initializeAuth } from "./firebase/auth";

const app = createApp(App);
const pinia = createPinia();

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(pinia).use(store).use(router).mount("#app");
app.use(pinia);
app.use(router);

// Initialize Firebase Authentication listener
initializeAuth();
