import { defineStore } from "pinia";
import {
  getAuth,
  sendEmailVerification,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  inMemoryPersistence,
} from "firebase/auth";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "@/firebase";

interface AuthState {
  user: unknown | null;
  userRole: string | null;
  loading: boolean;
  error: string | null;
  isRemembered: boolean;
  sessionChecked: boolean;
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    user: null,
    userRole: null,
    loading: false,
    error: null,
    isRemembered: false,
    sessionChecked: false,
  }),

  actions: {
    async login(email: string, password: string, rememberMe: boolean) {
      try {
        this.loading = true;
        this.error = null;
        const auth = getAuth();

        await setPersistence(
          auth,
          rememberMe ? browserLocalPersistence : browserSessionPersistence
        );

        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );

        // Only check Firebase Auth email verification status
        if (!userCredential.user.emailVerified) {
          this.error =
            "Por favor verifica tu correo electrónico antes de iniciar sesión";
          await signOut(auth);
          return false;
        }

        // Fetch user data from Firestore
        const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          this.user = {
            ...userCredential.user,
            ...userData,
            emailVerified: userCredential.user.emailVerified,
          };
          this.userRole = userData.role;
          this.isRemembered = rememberMe;
          return true;
        }
        return false;
      } catch (error) {
        this.error = "Error al iniciar sesión";
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async register(
      email: string,
      password: string,
      userData: {
        firstName: string;
        lastName: string;
        role: string;
      }
    ) {
      try {
        this.loading = true;
        const auth = getAuth();
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        // Send verification email
        await sendEmailVerification(userCredential.user);

        // Create user document in Firestore
        await setDoc(doc(db, "users", userCredential.user.uid), {
          email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          role: userData.role,
          createdAt: new Date(),
          emailVerified: false,
        });

        this.user = {
          ...userCredential.user,
          role: userData.role,
          emailVerified: false,
        };
        this.userRole = userData.role;

        return userCredential.user;
      } catch (error) {
        this.error = "Error al registrar usuario";
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async logout() {
      try {
        const auth = getAuth();
        await signOut(auth);
        this.user = null;
        this.userRole = null;
        this.isRemembered = false;
        this.sessionChecked = false;
      } catch (error) {
        console.error("Error logging out:", error);
        throw error;
      }
    },

    async fetchUserRole(userId: string) {
      try {
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          this.userRole = userData.role;
        }
      } catch (error) {
        this.error = "Error fetching user role";
        throw error;
      }
    },

    setupSessionTimeout() {
      const SESSION_DURATION = 20 * 60 * 1000; // 20 minutes
      setTimeout(async () => {
        await this.logout();
        window.location.href = "/login";
      }, SESSION_DURATION);
    },

    async checkSession() {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            this.userRole = userData.role;
            this.user = {
              ...user,
              ...userData,
              emailVerified: user.emailVerified,
            };
            // Check if session is remembered
            await getAuth().setPersistence(browserLocalPersistence);
            this.isRemembered = true;

            // Setup timeout only if not remembered
            if (!this.isRemembered) {
              this.setupSessionTimeout();
            }

            return true;
          }
        } catch (error) {
          console.error("Error checking session:", error);
        }
      }
      return false;
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
    isStaff: (state) => state.userRole === "staff",
    isClient: (state) => state.userRole === "client",
  },
});
